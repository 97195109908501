import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { LiffProvider } from 'react-liff';
import helper from "./services/helper";

function fnHandleLiff(liff) {
  console.log('error', liff.error)
}

const path = window.location.pathname
console.log('process.env INDEX >>', process.env.REACT_APP_ENV)
const liffId = helper.getLiffIdByENV(path, process.env.REACT_APP_ENV)
let isLoginBrowser = helper.isLoginBrowser

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <LiffProvider liffId={liffId} withLoginOnExternalBrowser={isLoginBrowser} callback={fnHandleLiff}>
    <App/>
  </LiffProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
