import * as React from "react";
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DatePicker } from "@mui/x-date-pickers";
import { makeStyles } from '@mui/styles';



const useStyles = makeStyles({
  input: {
    "& .MuiInputBase-root": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#000000"
      },
      "& .MuiInputBase-input": {
        "&::placeholder": {
          color: "black !important",
          opacity :"0.8 !important"
        },
      }
    },

  },
  inputError: {
    "& .MuiInputBase-root": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#d32f2f"
      }
    },
  }
});

export default function CustomDate(props) {
  const classes = useStyles();
  // const [value, setValue] = React.useState(dayjs(""));

  // const handleChange = (newValue) => {
  //   setValue(newValue);
  // };



  console.log(props.value)
  console.log(dayjs(props.value, 'YYYY-MM-DD'))
  console.log(dayjs(props.value, 'YYYY-MM-DD').isValid())
  return (
    <div className="w-full">
      <p className="text-[20px] text-left">
        {props.text}
        {props.required && <span className="!text-red"> *</span>}
        <div className="date-custom">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
              <DatePicker
                slotProps={{ textField: { placeholder: props.placeholder } }}
                // className={" text-left border-[1px] border-black py-[7px] px-[20px] text-[16px] rounded-full w-full " + (props.error && "!border-red")}
                className={props.error ? classes.inputError : classes.input}
                format="DD/MM/YYYY"
                value={dayjs(props.value, 'YYYY-MM-DD').isValid() ? dayjs(props.value, 'YYYY-MM-DD') : null}
                onChange={(e) => {
                  e = dayjs(e).format('YYYY-MM-DD')
                  props.onChange(e, props?.name);
                }}
                error={props.error}
                renderInput={(params) => {
                  return <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      readOnly: true,
                      className: classes.input,
                    }}
                  />
                }}
              />
            </Stack>
          </LocalizationProvider>
        </div>
      </p>
    </div>
  );

  // return (
  //   <LocalizationProvider dateAdapter={AdapterDayjs}>
  //     <Stack spacing={3}>

  //     <DesktopDatePicker
  //         className='text-left border-[1px] border-black py-[7px] px-[20px] text-[16px] rounded-full w-full'
  //         text={text}
  //         // label={text}
  //         inputFormat="DD/MM/YYYY"
  //         value={value}
  //         onChange={handleChange}
  //         renderInput={(params) => <TextField {...params} />}
  //       />
  //     </Stack>
  //   </LocalizationProvider>
  // );
}
