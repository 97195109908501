import React from "react";
import CustomButton from "../global/CustomButton";

export default function Cookie(props) {
  return (
    <div
      id="cookie"
      className="transition duration-300 fixed top-0 bottom-0 left-0 right-0 min-h-screen bg-black/20 z-[100] flex items-end"
    >
      <div id="cookie-popup" className="w-full p-[24px] pb-[38px] bg-white">
        <p className="text-[16px] font-normal text-justify">
          <span className="text-red">เว็บไซต์นี้ใช้คุกกี้</span>{" "}
          เพื่อเพิ่มประสบการณ์ที่ดีของผู้ใช้ ให้ท่านได้รับประสบการณ์
          ที่ดีที่สุดในการใช้เว็บไซต์ และเพื่อทำการวิเคราะห์การเข้าถึง
          เมื่อท่านกด <span className="text-red">‘ยินยอม’</span>{" "}
          ถือว่าท่านได้ยินยอมในการใช้{" "}
          <span
            className="underline cursor-pointer decoration-1"
            onClick={() => {
              if (props.openPdpa) props.openPdpa();
            }}
          >
            นโยบายความเป็นส่วนตัว
          </span>{" "}
          ของเราแล้ว
        </p>
        <div className="mt-[20px] gap-[12px] flex items-center justify-end">
          <CustomButton
            text="ยินยอม"
            small
            function={() => {
              if (props.function) props.function(true);
            }}
          />
          <CustomButton
            text="ไม่ยินยอม"
            small
            function={() => {
              if (props.function) props.function(false);
            }}
          />
        </div>
      </div>
    </div>
  );
}
