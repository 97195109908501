import React from "react";
import Cleave from "cleave.js/react";

export default function CustomInput(props) {
  const Input = () => (
    <input
      className={
        `border-[1px] border-black py-[7px] px-[20px] text-[16px] rounded-full w-full ` +
        (props.error && "!border-red")
      }
      placeholder={props.placeholder}
      defaultValue={props?.value ?? ""}
      onChange={(e) => {
        props.onChange(e, props?.name);
      }}
      maxLength={props.maxLength}
    />
  );

  const CleaveInput = () => (
    <Cleave
      className={
        `border-[1px] border-black py-[7px] px-[20px] text-[16px] rounded-full w-full ` +
        (props.error && "!border-red")
      }
      options={props.patternFormat ?? null}
      placeholder={props.placeholder}
      value={props?.value ?? ""}
      onChange={(e) => {
        props.onChange(e, props?.name);
      }}
      disabled={props.disabled}
      maxLength={props.maxLength}
    />
  );

  return (
    <div className="w-full">
      <p className={"text-[20px] text-left "}>
        {props.text}
        {props.required && <span className="!text-red"> *</span>}
      </p>
      {
        props.patternFormat ?
        CleaveInput()
        :Input()
      }
    </div>
  );
}
