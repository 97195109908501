import React, { useState } from "react";
import banner from "../../assets/images/profile/BG.png";
import MemberCard from "../../components/profile/MemberCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faCar, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-regular-svg-icons";

import { useHistory } from "react-router-dom";
import PDPA from "../../components/popup/PDPA";

import { SearchProfile, UpdateProfile } from "../../services/Register";
import { SearchId } from "../../services/SearchId";
import { SearchDdlCarOther, SearchDdlAddress } from "../../services/Dropdown";
import { useLiff } from "react-liff";
import Navbar from "../../components/Navbar";
import helper from "../../services/helper";
import dayjs from "dayjs";
import phoneIcon from "../../assets/images/icon/event.png";
import eventIcon from "../../assets/images/icon/phone.png";
import termIcon from "../../assets/images/icon/term.png";

const initialAddressList = [
  {
    id: 7600,
    name: "แขวงคลองเจ้าคุณสิงห์ เขตวังทองหลาง กรุงเทพมหานคร 10310",
    zipcode: "10310",
    province: "กรุงเทพมหานคร",
    amphoe: "เขตวังทองหลาง",
    subDistrict: "แขวงคลองเจ้าคุณสิงห์",
  },
];
const initialAddress = {
  id: 7506,
  name: "แขวงห้วยขวาง เขตห้วยขวาง กรุงเทพมหานคร 10310",
  zipcode: "10310",
  province: "กรุงเทพมหานคร",
  amphoe: "เขตห้วยขวาง",
  subDistrict: "แขวงห้วยขวาง",
};

const CAR_OTHER_OPTION = helper.OPTION.CAR_OTHER

export default function Profile(props) {
  const [pdpa, setPdpa] = useState(false);
  const [isHaveCar, setIsHaveCar] = useState("ใช่");
  const [memberId, setMemberId] = useState(null);
  const [carName, setCarName] = useState(null);
  const [carOtherList, setCarOtherList] = useState([]);

  // GET LINE DATA
  let lineId = helper.defaultLineID;
  let LineProfile = null;
  const { isLoggedIn, liff } = useLiff();
  if (liff.getDecodedIDToken) {
    LineProfile = liff.getDecodedIDToken() ?? null;
    lineId = helper.CustomLine(LineProfile?.sub);
  }

  const [addressList, setAddressList] = useState(initialAddressList);
  const [address, setAddress] = useState(initialAddress);
  const [formData, setFormData] = useState({
    memberCode: "",
    fullName: "",
    email: "",
    facebook: "",
    mobileNumber: "",
    birthdate: "",
    isHaveCar: false,
    carDetail: "",
    houseNum: "",
    buildingName: "",
    alley: "",
    road: "",
    addressDetail: "",
    ownerCar: ""
  });

  React.useEffect(() => {
    getSearchDdlCarOther();
    getSearchId();
  }, []);

  React.useEffect(() => {
    if (memberId) {
      getProfile();
    }
  }, [memberId]);

  async function getSearchId() {
    return await SearchId({
      params: { lineId: lineId || null },
    })
      .then(async (response) => {
        if (!response.data.body.memberId) {
          window.location.replace("/register");
        } else {
          setMemberId(response.data.body.memberId);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getProfile() {
    return await SearchProfile({
      params: { memberId: memberId },
    })
      .then(async (response) => {
        const resData = response.data;
        if (resData.resultCode == "20000") {
          console.log("resData.body >>", resData.body);
          const body = resData.body;
          const updateOwnCar = body.isHaveCar === "1" ? "ใช่" : "ไม่ใช่";
          let updateFormData = {
            memberCode: body.memberCode,
            fullName: body.fullName,
            nickName: body.nickName,
            email: body.email,
            facebook: body.faceBookName,
            mobileNumber: body.mobileNumber,
            birthdate: body.birthdate,
            isHaveCar: body.isHaveCar,
            isHaveMisubishiCar : body.isHaveMisubishiCar,
            carDetail: body.carDetail,
            houseNum: body.houseNum,
            buildingName: body.buildingName,
            alley: body.alley,
            road: body.road,
            addressDetail: body.addressDetail,
          };
          if (body.isHaveCar === '1') {
            if (body.isHaveMisubishiCar) {
            } else {
              if (body.carBrandType == 9999) {
                updateFormData.ownerCar = body.carBrand ? body.carBrand : ''
              } else {
                updateFormData.ownerCar = CAR_OTHER_OPTION.find(item => item.id === body.carBrandType).name
              }
            }
          } else {
            updateFormData.ownerCar = ''
          }
          console.log("updateOwnCar >>", updateOwnCar);
          console.log("updateFormData >>", updateFormData);
          setIsHaveCar(updateOwnCar);
          setFormData(updateFormData);
          setAddress(body.address);
          console.log('findCarById >>', findCarById(carOtherList, formData.carDetail))
        } else {
          // navigate(-1)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getSearchDdlCarOther(search) {
    try {
      const { data } = await SearchDdlCarOther({ params: { search } });
      if (+data["resultCode"] === 20000) {
        const body = data.body || [];
        // console.log("body", body);
        if (Object.keys(body).length === 0) {
          setCarOtherList([]);
        } else {
          setCarOtherList(body);
        }
      } else {
        setCarOtherList([]);
      }
    } catch (error) {
      console.log("getSearchDdlCarOther error", error);
    }
  }

  const history = useHistory();

  document.body.style.overflow = !pdpa ? "auto" : "hidden";

  return (
    <div>
      <div className="">
        <div className="text-center">
          <img className=" w-full object-cover h-[120px]" src={banner} alt="" />
        </div>
        <main className="mt-[-92px]">
          <div className="relative">
            <div className="px-[25px]">
              <MemberCard profileData={formData} carList={carOtherList} />
            </div>
            <div id="profile" className="relative section !z-[1] mt-[20px]">
              <div className="w-full pb-[50px] px-[20px] flex flex-col gap-[16px]">
                <div className="flex items-center justify-between border-b-[1px] border-lightGray2 pb-[4px]">
                  <p className="text-[20px] font-bold text-left">
                    ข้อมูลส่วนตัว
                  </p>
                  <FontAwesomeIcon
                    icon={faPen}
                    className="h-[18px]"
                    onClick={() => history.push("/edit-profile")}
                  />
                </div>

                <div>
                  <p className="text-[14px] text-left">ชื่อ-นามสกุล</p>
                  <p className="text-[20px] mt-[2px] text-left leading-[20px]">
                    {formData.fullName}
                  </p>
                </div>

                <div>
                  <p className="text-[14px] text-left">อีเมล</p>
                  <p className="text-[20px] mt-[2px] text-left leading-[20px]">
                    {formData.email}
                  </p>
                </div>

                <div>
                  <p className="text-[14px] text-left">ชื่อ Facebook</p>
                  <p className="text-[20px] mt-[2px] text-left leading-[20px]">
                    {formData.facebook}
                  </p>
                </div>

                <div>
                  <p className="text-[14px] text-left">เบอร์โทรศัพท์</p>
                  <p className=" text-[20px] mt-[2px] text-left leading-[20px]">
                    {/* {formData.mobileNumber} */}
                    {helper.toPhoneNumber(formData.mobileNumber)}
                  </p>
                </div>
                {
                  formData.ownerCar
                    ? (
                      <div>
                        <p className="text-[14px] text-black text-left">
                          รุ่นรถของคุณ
                        </p>
                        <p className="uppercase text-[16px] font-bold text-black text-left red-gradient-text leading-[20px]">{formData.ownerCar}</p>
                      </div>
                    )
                    : <></>
                }
                <div>
                  <p className="text-[14px] text-left">วัน เดือน ปี เกิด</p>
                  <p className=" text-[20px] mt-[2px] text-left leading-[20px]">
                    {/* {formData.mobileNumber} */}
                    {formData.birthdate ? dayjs(formData.birthdate).format('DD/MM/YYYY') : '-'}
                  </p>
                </div>

                <div>
                  <p className="text-[14px] text-left">ที่อยู่</p>
                  <p className="text-[20px] mt-[2px] text-left leading-[20px]">
                    {`${formData.houseNum ?? ""} ${formData.buildingName ?? ""
                      } ${formData.alley ? `${formData.alley}` : ""} ${formData.road ? `${formData.road}` : ""
                      } ${formData.addressDetail}`}
                  </p>
                </div>

                <div className="border-b-[1px] border-lightGray2" />
                <div className="flex flex-col gap-[12px]">
                  <div className="flex items-center gap-[6px]">
                    <img className="w-[16px] mb-[2px]" src={eventIcon} alt="" />
                    <p
                      className="text-left text-[16px]"
                      onClick={() => history.push("/event")}
                    >
                      กิจกรรม
                    </p>
                  </div>

                  <div className="flex items-center gap-[6px]" onClick={() => window.open('https://www.facebook.com/Mjourneythailand')}>
                    <img className="w-[16px] mb-[2px]" src={phoneIcon} alt="" />
                    <p className="text-left text-[16px]">ติดต่อเรา</p>
                  </div>

                  <div
                    className="flex items-center gap-[6px]"
                    onClick={() => setPdpa(true)}
                  >
                    <img className="w-[16px] mb-[2px]" src={termIcon} alt="" />
                    <p className="text-left text-[16px]">
                      นโยบายความเป็นส่วนตัว
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div 
            onClick={() => history.push("/thankyou", { memberCode: "00003" })}
          >
            CLICK THANK YOU
          </div> */}
        </main>
      </div>

      {pdpa && <PDPA function={() => setPdpa(false)} />}
    </div>
  );
}

function findCarById(carGroupList = [], carDetailId = null) {
  // return carList[0].groupName
  console.log('carGroupList >>', carGroupList)
  for (const d1 of carGroupList) {
    for (const d2 of d1.carList) {
      if (d2.id === Number(carDetailId)) {
        if (d1.groupName === 'Other') {
          return d2.name
        }
        return d1.groupName
      }
    }
  }
  return carDetailId
}