import "./App.css";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./assets/css/page.css";
import Register from "./pages/Register";
import Navbar from "./components/Navbar";
import Thankyou from "./pages/Thankyou";
import Profile from "./pages/profile/Profile";
import EditProfile from "./pages/profile/EditProfile";
import Event from "./pages/Event";
import ShareEvent from "./pages/ShareEvent";
import Home from "./pages/Home";
import { useLiff } from "react-liff";
import helper from "./services/helper";

function App() {
  const { isLoggedIn, liff  } = useLiff();
  const pass = !helper.isLoginBrowser
  return (
    <>
      { ( isLoggedIn || pass ) && (
        <BrowserRouter>
          <Switch>
            {/* <Route path="/">
              <div className="App">
                <Home />
              </div>
            </Route> */}
            <Route path="/register">
              <div className="App">
                <Navbar />
                <Register />
              </div>
            </Route>

            <Route path="/thankyou">
              <div className="App">
                <Navbar />
                <Thankyou />
              </div>
            </Route>

            <Route path="/profile">
              <div className="App">
                <Navbar />
                <Profile />
              </div>
            </Route>

            <Route path="/edit-profile">
              <div className="App">
                <Navbar />
                <EditProfile />
              </div>
            </Route>

            <Route path="/event">
              <div className="App">
                <Navbar />
                <Event />
              </div>
            </Route>

            <Route path="/share-event">
              <div className="App">
                <Navbar />
                <ShareEvent />
              </div>
            </Route>
          </Switch>
        </BrowserRouter>
      )}
    </>
  );
}

export default App;
