import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

export default function CustomPagination(props) {
  const { count, limit, offset } = props
  // const PAGELIST = countToPageList(count, limit)
  const PAGELIST = countToPageList(count, limit) ?? []
  const PAGE = offsetToPage(offset , limit)
  
  return (
   <>
    {/* {JSON.stringify({ count, limit, offset, PAGE})} */}
    {/* {JSON.stringify(PAGELIST)} */}
    <div className="mt-[36px] section gap-[16px]">
      <div className="cursor-pointer p-[7px] rounded-full border-[2px] border-lightGray2 w-fit section">
        <FontAwesomeIcon
          onClick={()=>{
            const item = PAGE - 1
            if (item > 0) {
              const OFFSET = pageToOffset(item, limit)
              props.onChange({ count, limit, offset: OFFSET })
            }
          }}
          icon={faChevronLeft}
          className="h-[16px] w-[16px] text-lightGray2"
        />
      </div>
      <div className="flex gap-[12px]">
        {
          PAGELIST.map(item => {
            const active = PAGE === item ? '' : '2'
            let classPage = `bg-lightGray2 border-lightGray2`
            if (PAGE === item) {
              classPage = `bg-lightGray3 border-lightGray3`
            }
            return (
              <div
                onClick={()=>{
                  const OFFSET = pageToOffset(item, limit)
                  props.onChange({ count, limit, offset: OFFSET })
                }}
                className={`cursor-pointer p-[7px] rounded-full border-[2px] ${classPage} w-fit section`}
                key={item}
              >
                <p className="h-[16px] w-[16px] text-[16px] font-bold text-white section">
                  {item}
                </p>
              </div>
            )
          })
        }
      </div>
      

      <div className="cursor-pointer p-[7px] rounded-full border-[2px] border-lightGray2 w-fit section">
        <FontAwesomeIcon
          onClick={()=>{
            const item = PAGE + 1
            const MAXPAGE = PAGELIST.length
            if (item <= MAXPAGE) {

              const OFFSET = pageToOffset(item, limit)
              props.onChange({ count, limit, offset: OFFSET })
            }
          }}
          icon={faChevronRight}
          className="h-[16px] w-[16px] text-lightGray2"
        />
      </div>
    </div>
   </>
  );
}

function countToPageList(_count = 0, _limit = 0) {
  let list = []
  let loop = _count/_limit
  if (_count%_limit !==0) {
    loop = loop + 1
  }
  for(let i=1; i <= loop; i++){
    list.push(i)
  }
  return list
}
function pageToOffset(page = 0, itemsPerPage = 0) {
  const offset = (page - 1) * itemsPerPage
  return offset
}
function offsetToPage(_offset = 0, itemsPerPage = 0) {
  const page = Math.floor( _offset / itemsPerPage ) + 1
  return page
}
