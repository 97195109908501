import React, { useState } from "react";
import CustomGradientButton from "../../components/global/CustomGradientButton";
import CustomDropdown from "../../components/global/CustomDropdown";
import CustomInput from "../../components/global/CustomInput";
import CustomRadio from "../../components/global/CustomRadio";
import CustomAutocomplete from "../../components/global/CustomAutocomplete";
import Outher from "../../components/global/outher";
import Brand from "../../components/global/Brand";
import banner from "../../assets/images/profile/edit-banner.png";
import { SearchProfile, UpdateProfile } from "../../services/Register";
import { SearchId } from "../../services/SearchId";
import { SearchDdlCarOther, SearchDdlAddress } from "../../services/Dropdown";
import { useHistory, useNavigate } from "react-router-dom";
import SelectComponent from "../../components/global/SelectComponent";

import { useLiff } from "react-liff";
import helper from "../../services/helper";
import PATTERN_FORMAT from "../../patternFormat.json";

import Swal from "sweetalert2";
import CustomDate from "../../components/global/CustomDate";
import dayjs from "dayjs";
import tyFooter from "../../assets/images/thankyou/bg-footer.png"

const initialAddressList = [
  // {
  //   id: 7600,
  //   name: "แขวงคลองเจ้าคุณสิงห์ เขตวังทองหลาง กรุงเทพมหานคร 10310",
  //   zipcode: "10310",
  //   province: "กรุงเทพมหานคร",
  //   amphoe: "เขตวังทองหลาง",
  //   subDistrict: "แขวงคลองเจ้าคุณสิงห์"
  // },
];
const initialAddress = {
  // id: 7506,
  // name: "แขวงห้วยขวาง เขตห้วยขวาง กรุงเทพมหานคร 10310",
  // zipcode: "10310",
  // province: "กรุงเทพมหานคร",
  // amphoe: "เขตห้วยขวาง",
  // subDistrict: "แขวงห้วยขวาง"
};

const CAR_OTHER_OPTION = helper.OPTION.CAR_OTHER

export default function EditProfile() {
  const [isHaveCar, setIsHaveCar] = useState("ใช่");
  const [isMitsu, setIsMitsu] = useState("ใช่");
  const [memberId, setMemberId] = useState(null);
  const [carOtherList, setCarOtherList] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);

  // GET LINE DATA
  let lineId = helper.defaultLineID;
  let LineProfile = null;
  const { isLoggedIn, liff } = useLiff();
  if (liff.getDecodedIDToken) {
    LineProfile = liff.getDecodedIDToken() ?? null;
    lineId = helper.CustomLine(LineProfile?.sub);
  }

  const [errorInput, setErrorInput] = useState([]);
  const [addressList, setAddressList] = useState(initialAddressList);
  const [address, setAddress] = useState(initialAddress);
  const [formData, setFormData] = useState({
    fullName: "",
    nickName: "",
    email: "",
    mobileNumber: "",
    birthdate: "",
    carDetail: null,
    carBrandType: "",
    carBrand: "",
    carBrandModel: "",
    houseNum: "",
    buildingName: "",
    alley: "",
    road: "",
    // addressDetail: "",
  });

  const [formErrors, setFormErrors] = useState({
    fullName: false,
    nickName: false,
    email: false,
    mobileNumber: false,
    birthdate: false,
    carDetail: false,
    carBrandType: false,
    carBrandModel: false,
    carBrand: false,
    houseNum: false,
    buildingName: false,
    alley: false,
    road: false,
    address: false,
    // addressDetail: false,
  });

  React.useEffect(() => {
    getSearchDdlCarOther();
    getSearchId();
  }, []);

  React.useEffect(() => {
    if (memberId) {
      getProfile();
    }
  }, [memberId]);

  async function getSearchId() {
    return await SearchId({
      params: { lineId: lineId || null },
    })
      .then(async (response) => {
        if (!response.data.body.memberId) {
          window.location.replace("/register");
        } else {
          setMemberId(response.data.body.memberId);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getProfile() {
    return await SearchProfile({
      params: { memberId: memberId },
    })
      .then(async (response) => {
        const resData = response.data;
        if (resData.resultCode == "20000") {
          console.log("resData.body >>", resData.body);
          const body = resData.body;
          const updateOwnCar = body.isHaveCar === "1" ? "ใช่" : "ไม่ใช่";
          const updateIsMitsu = body.isHaveMisubishiCar ? "ใช่" : "ไม่ใช่";
          const updateFormData = {
            memberCode: body.memberCode,
            fullName: body.fullName,
            nickName: body.nickName,
            email: body.email,
            mobileNumber: body.mobileNumber,
            faceBookName: body.faceBookName,
            birthdate: body.birthdate,
            isHaveCar: body.isHaveCar,
            carDetail: body.carDetail,
            carBrandType: body.carBrandType,
            carBrand: body.carBrand,
            carBrandModel: body.carBrandModel,
            houseNum: body.houseNum,
            buildingName: body.buildingName,
            alley: body.alley,
            road: body.road,
            addressDetail: body.addressDetail,
          };
          setIsHaveCar(updateOwnCar);
          setIsMitsu(updateIsMitsu)
          setFormData(updateFormData);
          setAddress(body.address);
        } else {
          // navigate(-1)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getSearchDdlAddress(search) {
    try {
      const { data } = await SearchDdlAddress({ params: { search } });
      if (+data["resultCode"] === 20000) {
        const body = data.body || [];
        // console.log("body", body);
        if (Object.keys(body).length === 0) {
          setAddressList([]);
        } else {
          setAddressList(body);
        }
      } else {
        setAddressList([]);
      }
    } catch (error) {
      console.log("getSearchDdlAddress error", error);
    }
  }

  async function getSearchDdlCarOther(search) {
    try {
      const { data } = await SearchDdlCarOther({ params: { search } });
      if (+data["resultCode"] === 20000) {
        const body = data.body || [];
        // console.log("body", body);
        if (Object.keys(body).length === 0) {
          setCarOtherList([]);
        } else {
          setCarOtherList(body);
        }
      } else {
        setCarOtherList([]);
      }
    } catch (error) {
      console.log("getSearchDdlCarOther error", error);
    }
  }

  React.useEffect(() => {
    validateInput();
  }, [formData, address]);

  const handleInputChange = (event, name) => {
    console.log('handleInputChange >>', event, name)
    const target = event.target;
    if (target) {
      setFormData({ ...formData, [name]: target.value });
    } else {
      setFormData({ ...formData, [name]: event });
    }
  };

  function validateInput() {
    let carDetail = false
    let carBrandType = false
    let carBrand = false
    let carBrandModel = false
    if (isHaveCar === 'ใช่') {
      if (isMitsu === 'ใช่') {
        carDetail = !!formData.carDetail ? false : true
        if (+formData.carDetail === 24) {
          carBrand = !!formData.carBrand ? false : true
        }
      } else {
        carBrandType = !!formData.carBrandType ? false : true
        carBrand = !!formData.carBrand ? false : true
        if (+formData.carBrandType === 9999) {
          carBrandModel = !!formData.carBrandModel ? false : true
        }
      }
    }

    let updatedError = {
      fullName: !!formData.fullName ? false : true,
      nickName: !!formData.nickName ? false : true,
      email: !!formData.email ? false : true,
      mobileNumber: !!formData.mobileNumber ? false : true,
      birthdate: !!formData.birthdate ? false : true,
      houseNum: !!formData.houseNum ? false : true,
      buildingName: !!formData.buildingName ? false : true,
      alley: !!formData.alley ? false : true,
      road: !!formData.road ? false : true,
      address: address && !!address.id ? false : true,
      // addressDetail: !!formData.addressDetail ? false : true,
      isHaveCar: isHaveCar === 'ไม่ใช่' ? false : (isMitsu !== null) ? false : true,
      carDetail: carDetail,
      carBrandType: carBrandType,
      carBrandModel: carBrandModel,
      carBrand: carBrand,

    };

    let check = false;
    const entries = Object.entries(updatedError);
    console.log("entries >>", entries);
    // eslint-disable-next-line
    for (const [key, value] of entries) {
      if (value === true) {
        check = true;
        break;
      }
    }
    if (!check) {
      return true;
    }

    setFormErrors(updatedError);
    return false;
  }

  React.useEffect(() => {
    if (isSubmit) {
      console.log("useEffect isSubmit");
      onSave();
    }
  }, [isSubmit]);

  async function onSave() {
    setIsSubmit(true);
    console.log('ON SAVE')
    if (isSubmit) {
      const _isHaveCar = isHaveCar === "ใช่" ? "1" : "0";
      const _mobileNumber = helper.removeNot0_9(formData.mobileNumber);

      let payload = {
        memberId,
        ...formData,
        mobileNumber: _mobileNumber,
        address: address?.id ?? null,
        isHaveCar: _isHaveCar,
      };


      if (isHaveCar === 'ไม่ใช่') {
        delete payload.carBrand
        delete payload.carBrandType
        delete payload.carDetail
        delete payload.carBrandModel
      } else {
        if (isMitsu === 'ใช่') {
          if (+payload.carDetail !== 24) {
            delete payload.carBrand
          }
          delete payload.carBrandType
          delete payload.carBrandModel
        } else {
          delete payload.carDetail
          console.log(payload.carBrandType)
          if (+payload.carBrandType !== 9999) {
            delete payload.carBrandModel
          }
        }
      }
      console.log("payload >>", payload);
      if (validateInput()) {
        try {
          const { data } = await UpdateProfile({ data: payload });
          console.log("resData.data >>", data);
          if (data.resultCode === 20000) {
            history.push("/profile");
          } else if (data.resultCode === 40401) {
            // alert(data.body.message)
            Swal.fire({
              title: "แจ้งเตือน",
              html: data.body.message,
              icon: "warning",
            });
            setErrorInput(data.body.error);
          }
        } catch (error) {
          console.log("update UpdateProfile error", error);
        }
      }
    }
  }

  const history = useHistory();
  console.log('formData.birthdate >>', formData.birthdate)
  return (
    <div>
      <div id="edit-profile" className="pt-[0px] z-1 relative section flex-col">
        <div className="w-full pb-[25px]">
          <img className="w-full" src={banner} alt="" />
          <div className="px-[20px] mt-[24px] flex flex-col gap-[14px]">
            <CustomInput
              text="ชื่อ-นามสกุล"
              name="fullName"
              placeholder="กรุณากรอกชื่อ-นามสกุล"
              value={formData.fullName}
              onChange={handleInputChange}
              error={isSubmit && !formData.fullName}
              required
            />
            <CustomInput
              text="ชื่อเล่น"
              name="nickName"
              placeholder="กรุณากรอกชื่อเล่น"
              value={formData.nickName}
              onChange={handleInputChange}
              error={isSubmit && !formData.nickName}
              required
            />
            <CustomInput
              text="อีเมล"
              name="email"
              placeholder="กรุณากรอกอีเมลของคุณ"
              value={formData.email}
              onChange={handleInputChange}
              error={
                isSubmit && (!formData.email || errorInput.includes("email"))
              }
              required
            />
            <CustomInput
              text="ชื่อ Facebook"
              name="faceBookName"
              placeholder="กรุณากรอก Facebook ของคุณ"
              value={formData.faceBookName}
              onChange={handleInputChange}
              isMMC={true}
              error={
                isSubmit &&
                (!formData.faceBookName || errorInput.includes("faceBookName"))
              }
              patternFormat={PATTERN_FORMAT.faceBookName}
              required
            />
            <CustomInput
              text="เบอร์โทรศัพท์"
              type="number"
              name="mobileNumber"
              placeholder="กรุณากรอกเบอร์โทรศัพท์ของคุณ"
              value={formData.mobileNumber}
              onChange={handleInputChange}
              disabled={true}
              error={
                isSubmit &&
                (!formData.mobileNumber || errorInput.includes("mobileNumber"))
              }
              patternFormat={PATTERN_FORMAT.phone}
              maxLength={13}
              required
            />
            <CustomDate
              text="วัน เดือน ปี เกิด"
              type="date"
              name="birthdate"
              placeholder="กรุณากรอกวัน เดือน ปี เกิดของคุณ"
              value={formData.birthdate}
              format="DD/MM/YYYY"
              onChange={handleInputChange}
              error={
                isSubmit &&
                (!formData.birthdate || errorInput.includes("birthdate"))
              }
              patternFormat={PATTERN_FORMAT.birthdate}
              required
            />
            <CustomRadio
              name="isHaveCar"
              text="คุณมีรถยนต์หรือไม่"
              required
              value={isHaveCar}
              content={["ใช่", "ไม่ใช่"]}
              onChange={setIsHaveCar}
            />
            {isHaveCar === "ใช่" &&
              <>
                <CustomRadio
                  name="isMitsu"
                  text="คุณเป็นเจ้าของรถ Mitsubishi หรือไม่"
                  required
                  value={isMitsu}
                  content={["ใช่", "ไม่ใช่"]}
                  onChange={setIsMitsu}
                />
                {isMitsu === "ใช่" && (
                  <>
                    <CustomDropdown
                      type="group"
                      name="carDetail"
                      text="เลือกรุ่นรถที่คุณมี"
                      placeholder="กรุณาเลือกรุ่นรถที่คุณมี"
                      required
                      value={formData.carDetail}
                      content={carOtherList}
                      onChange={handleInputChange}
                      error={isSubmit && !formData.carDetail}
                    />
                    {
                      helper.isGroupNameOther(formData.carDetail, carOtherList) && (
                        <CustomInput
                          text="รุ่นรถของคุณ"
                          name="carBrand"
                          placeholder="กรุณาระบุรุ่นรถของคุณ"
                          value={formData.carBrand}
                          onChange={handleInputChange}
                          required
                          error={isSubmit && !formData.carBrand}
                        />
                      )
                    }

                  </>
                )}
                {isMitsu === "ไม่ใช่" && (
                  <div>
                    <CustomDropdown
                      name="carBrandType"
                      text="ยี่ห้อรถของคุณ"
                      placeholder="กรุณาเลือกยี่ห้อรถของคุณ"
                      required
                      value={formData?.carBrandType ?? ""}
                      content={CAR_OTHER_OPTION}
                      onChange={handleInputChange}
                      error={isSubmit && !formData.carBrandType}
                    />
                    {
                      +formData.carBrandType === 9999 ? (
                        <>
                          <div className="mt-5">
                            <CustomInput
                              text="ยี่ห้อรถของคุณ"
                              name="carBrand"
                              placeholder="กรุณาระบุยี่ห้อรถของคุณ"
                              value={formData.carBrand}
                              onChange={handleInputChange}
                              required
                              error={isSubmit && !formData.carBrand}
                            />
                          </div>
                          <div className="mt-5">
                            <CustomInput
                              text="รุ่นรถของคุณ"
                              name="carBrandModel"
                              placeholder="กรุณาระบุรุ่นรถของคุณ"
                              value={formData.carBrandModel}
                              onChange={handleInputChange}
                              required
                              error={isSubmit && !formData.carBrandModel}
                            />
                          </div>
                        </>
                      ) : (
                        <div className="mt-5">
                          <CustomInput
                            text="รุ่นรถของคุณ"
                            name="carBrand"
                            placeholder="กรุณาระบุรุ่นรถของคุณ"
                            value={formData.carBrand}
                            onChange={handleInputChange}
                            required
                            error={isSubmit && !formData.carBrand}
                          />
                        </div>
                      )
                    }
                  </div>
                )}
              </>
            }



            <div className="flex items-center justify-between gap-[24px]">
              <CustomInput
                text="บ้านเลขที่"
                name="houseNum"
                placeholder="กรอกบ้านเลขที่ของคุณ"
                value={formData.houseNum}
                onChange={handleInputChange}
                required
                error={isSubmit && !formData.houseNum}
              />
              <CustomInput
                text="หมู่บ้าน/อาคาร"
                name="buildingName"
                placeholder="กรอกที่อยู่ของคุณ"
                value={formData.buildingName}
                onChange={handleInputChange}
                required
                error={isSubmit && !formData.buildingName}
              />
            </div>
            <div className="flex items-center justify-between gap-[24px]">
              <CustomInput
                name="alley"
                text="ซอย"
                placeholder="กรอกที่อยู่ของคุณ"
                value={formData.alley}
                onChange={handleInputChange}
                required
                error={isSubmit && !formData.alley}
              />
              <CustomInput
                name="road"
                text="ถนน"
                placeholder="กรอกที่อยู่ของคุณ"
                value={formData.road}
                onChange={handleInputChange}
                required
                error={isSubmit && !formData.road}
              />
            </div>

            <CustomAutocomplete
              text="รหัสไปรษณีย์"
              name="zipcode"
              placeholder="กรอกที่อยู่ของคุณ"
              value={address?.zipcode ?? ""}
              InputCallBack={getSearchDdlAddress}
              onChange={(value, name, selectOption) => {
                setAddress(selectOption);
              }}
              required
              options={addressList}
              error={isSubmit && Object.keys(address).length === 0}
            />

            <CustomAutocomplete
              name="subDistrict"
              text="แขวง/ตำบล"
              placeholder="เลือก แขวง/ตำบล ของคุณ"
              value={address?.subDistrict ?? ""}
              InputCallBack={getSearchDdlAddress}
              onChange={(value, name, selectOption) => {
                setAddress(selectOption);
              }}
              required
              options={addressList}
              error={isSubmit && Object.keys(address).length === 0}
            />

            <CustomAutocomplete
              name="district"
              text="เขต/อำเภอ"
              placeholder="เลือก เขต/อำเภอ ของคุณ"
              value={address?.amphoe ?? ""}
              InputCallBack={getSearchDdlAddress}
              onChange={(value, name, selectOption) => {
                setAddress(selectOption);
              }}
              required
              options={addressList}
              error={isSubmit && Object.keys(address).length === 0}
            />

            <CustomAutocomplete
              name="province"
              text="จังหวัด"
              placeholder="เลือกจังหวัดของคุณ"
              value={address?.province ?? ""}
              InputCallBack={getSearchDdlAddress}
              onChange={(value, name, selectOption) => {
                setAddress(selectOption);
              }}
              required
              options={addressList}
              error={isSubmit && Object.keys(address).length === 0}
            />
            {/* <CustomInput
              text="รหัสไปรษณีย์"
              placeholder="กรอกที่อยู่ของคุณ"
              required
            />
            <CustomDropdown
              name="subDistrict"
              text="แขวง/ตำบล"
              placeholder="เลือก แขวง/ตำบล ของคุณ"
              required
              content={["เมือง", "เทพารักษ์"]}
            />
            <CustomDropdown
              name="district"
              text="เขต/อำเภอ"
              placeholder="เลือก เขต/อำเภอ ของคุณ"
              required
              content={["เมือง", "เทพารักษ์"]}
            />
            <CustomDropdown
              name="province"
              text="จังหวัด"
              placeholder="เลือกจังหวัดของคุณ"
              required
              content={["ชลบุรี", "สมุทรปราการ"]}
            /> */}
          </div>

          <div className="section mt-[35px]">
            <CustomGradientButton text="บันทึก" function={onSave} />
          </div>
        </div>
        <img className="w-full" src={tyFooter} alt="tyFooter" />
      </div>
    </div>
  );
}
