import React from "react";
import card from "../../assets/images/profile/card-profile1.png";
import tempImg from "../../assets/images/profile/temp.png";
import { useLiff } from 'react-liff';
import helper from "../../services/helper";

export default function MemberCard(props) {
  // GET LINE DATA
  let lineId = helper.defaultLineID 
  let LineProfile = null
  const { isLoggedIn, liff } = useLiff();

  console.log(props.carList)
  if (liff.getDecodedIDToken) {
    LineProfile = liff.getDecodedIDToken() ?? null
    lineId =  helper.CustomLine(LineProfile?.sub)
  }

  const { profileData } = props
  const findCarById = (carGroupList = [], carDetailId = null) =>{
    // return carList[0].groupName
    console.log('carGroupList >>', carGroupList)
    for (const d1 of carGroupList) {
      for (const d2 of d1.carList) {
        if (d2.id === Number(carDetailId)) {
          if (d1.groupName === 'Other') {
            return d2.name
          } 
          return d2.name
        }
      }
    }
    return carDetailId
  }

  return (
    <div
      id="member-card"
      style={{
        backgroundImage: `url(${card})`,
      }}
      className="bg-cover bg-center object-fit rounded-[10px] min-h-[120px] px-[24px] py-[16px] flex justify-between"
    >
      <div>
        <div className="flex items-center gap-[16px] mt-[2px]">
          <div>
            <p className="text-[20px] text-black text-left leading-[17px] font-bold ">
              สวัสดีคุณ 
            </p>
            <p className="text-[20px] text-black text-left leading-[17px] font-bold ">
              {profileData?.nickName ?? '-'}
            </p>
          </div>
          {
            profileData.isHaveCar === "1" &&  profileData.isHaveMisubishiCar
            ? (
              <div>
                <p className="text-[12px] text-black text-left">เจ้าของรถ</p>
                {/* <p className="uppercase text-[12px] font-bold text-black text-left red-gradient-text leading-[20px] font-MCC"> */}
                <p className="uppercase text-[12px] mt-[4px] text-[#EE1D23] font-bold text-black text-left leading-[12px] font-MCC">
                  {findCarById(props.carList, profileData.carDetail)}
                </p>
              </div>
            )
            : <></>
          }
        </div>
        
        {/* <div className="mt-[5px]">
        <p className="text-[12px] text-black text-left">เจ้าของรถ</p>
          <p className="uppercase text-[20px] font-bold text-black text-left red-gradient-text leading-[20px]">
          {findCarById(props.carList, profileData.carDetail)}
          </p>
        </div> */}
        <div className="flex items-center gap-[12px] mt-[2px]">
          <p className="text-[14px] leading-[15px] text-black text-left ">หมายเลขสมาชิก</p>
          {/* <p className="member-num-profile text-left text-[20px] text-black font-bold font-MCC"> */}
          <p className="member-num-profile text-left text-[16px] text-black font-bold font-MCC leading-[15px]">
            {profileData.memberCode}
          </p>
        </div>
      </div>
      <div className="z-[2] w-[80px] h-[80px] rounded-full object-fit">
        {/* <img src={tempImg} alt="" /> */}
        <img className="rounded-full" src={LineProfile?.picture ?? null} alt="" />
      </div>
    </div>
  );
}
