import React from "react";
import dayjs from "dayjs"
import helper from "../../services/helper";

const URL_PIC_DEF = 'https://www.mitsubishi-motors.co.th/content/dam/mitsubishi-motors-th/images/site-images/vehicle-page-assets/xpander-cross/2023/homepage/xpander-cross-homepage-desktop.jpg'
export default function EventComponent(props) {
  const toDate = (date) => {
    if (date) {
      return <span>{helper.toDateThai(date)}</span>
    }
    return <span></span>
  }
  const gotoLink = () => {
    window.open(props?.data?.link, '_blank')
  }
  return (
    <div className="event-component flex items-center gap-[20px]">
      <div>
        <div className="w-[120px] h-[120px] object-cover">
          <img  className="aspect-square object-cover" src={props?.data?.picture ?? URL_PIC_DEF} alt="" />
        </div>
      </div>
      <div className="flex flex-col gap-[13px]">
        <div className="flex flex-col gap-[4px]">
          <p className="text-[16px] font-bold text-left leading-[110%] line-clamp-2">
            {props?.data?.name ?? null}
          </p>
          <p className="text-[14px] text-left line-clamp-3  leading-[112%]">
            {props?.data?.description ?? null}
          </p>
        </div>
        <div>
          <p className="text-[14px] text-gray font-bold text-left">
            { toDate(props?.data?.startDate) }
            <span className="mx-[2px]">-</span>
            { toDate(props?.data?.endDate) }
          </p>
          <p className="text-red text text-[14px] font-bold text-left" onClick={gotoLink}>
            {props?.data?.label ?? 'รายละเอียดเพิ่มเติม'}
          </p>
        </div>
      </div>
    </div>
  );
}
