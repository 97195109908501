import React from "react";

export default function CustomButton(props) {
  return (
    <div
      className={
        `cursor-pointer transition duration-300 w-fit py-[9px] px-[48px] rounded-full border-[2px] border-black hover:!text-white hover:bg-black rounded-[100px] ` +
        (props.small && "py-[6px] px-[29px]")
      }
      onClick={() => {
        if (props.function) props.function();
      }}
    >
      <div
        className={
          `text-[20px] font-bold text-center ` + (props.small && "text-[14px]")
        }
      >
        {props.text}
      </div>
    </div>
  );
}
