import {get, post} from './Api';

export function SearchRegister({params}) {
    return get({url: '/searchRegister', params});
}

export function SearchProfile({params}) {
    return get({url: '/getProfile', params})
}

export function CreateProfile({data}) {
    return post({url: '/createProfile', data})
}

export function UpdateProfile({data}) {
    return post({url: '/updateProfile', data})
}