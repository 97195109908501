import axios from "axios";
import { Logger } from "./Logger"
console.log('process.env >>', process.env)
console.log('process.env >>', process.env.REACT_APP_BASE_URL)
export const baseUrl = process.env.REACT_APP_BASE_URL
export const headers = {
    Accept: 'application/form-data',
    'Content-Type': 'application/json',
};

axios.interceptors.request.use(function (config) {
  const tranId = Logger.getTranID()
    config.tranId = tranId
    Logger.info(`${config.method} REQ: ${tranId} => ${config.url}`, { params: config.params, payload: config.data })
    return config;
  }, function (error) {
    Logger.error(`ERROR Request`, { error: `Error ${error}` })
    return Promise.reject(error);
  });

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  const { config, data } = response
  Logger.info(`${config.method} RES: ${config.tranId} => ${config.url}`, { params: config.params, payload: config.data, data: data })
    return response;
  }, function (error) {
    Logger.error(`ERROR Response`, { error: `Error ${error}` })
    return Promise.reject(error);
  });

export function get({url, params}) {
    return axios({
        url,
        method: 'get',
        baseURL: baseUrl,
        headers: headers,
        params,
    })
}

export function post({url, params, data}) {
    return axios({
        url,
        method: 'post',
        baseURL: baseUrl,
        headers: headers,
        params,
        data,
    })
}