import { Checkbox } from "@material-ui/core";
import React, { useState } from "react";
import CustomGradientButton from "../components/global/CustomGradientButton";
import CustomDropdown from "../components/global/CustomDropdown";
import CustomInput from "../components/global/CustomInput";
import CustomAutocomplete from "../components/global/CustomAutocomplete";
import CustomRadio from "../components/global/CustomRadio";
import CustomDate from "../components/global/CustomDate";
import Brand from "../components/global/Brand";
import Outher from "../components/global/outher";

import banner from "../assets/images/register/banner.png";
// import footer from "../assets/images/register/Footer.png";
import Cookie from "../components/popup/Cookie";
import PDPA from "../components/popup/PDPA";

import { useHistory } from "react-router-dom";
import { SearchDdlAddress, SearchDdlCarOther } from "../services/Dropdown";
import { SearchRegister, CreateProfile } from "../services/Register";
import { useLiff } from "react-liff";
import helper from "../services/helper";
import PATTERN_FORMAT from "../patternFormat.json";

import Swal from "sweetalert2";
import { DatePicker, DesktopDatePicker } from "@mui/x-date-pickers";
import tyFooter from "../assets/images/thankyou/bg-footer.png"
const CAR_OTHER_OPTION = helper.OPTION.CAR_OTHER

const initialAddressList = [
  // {
  //   id: 7600,
  //   name: "แขวงคลองเจ้าคุณสิงห์ เขตวังทองหลาง กรุงเทพมหานคร 10310",
  //   zipcode: "10310",
  //   province: "กรุงเทพมหานคร",
  //   amphoe: "เขตวังทองหลาง",
  //   subDistrict: "แขวงคลองเจ้าคุณสิงห์"
  // },
];
const initialAddress = {
  // id: 7506,
  // name: "แขวงห้วยขวาง เขตห้วยขวาง กรุงเทพมหานคร 10310",
  // zipcode: "10310",
  // province: "กรุงเทพมหานคร",
  // amphoe: "เขตห้วยขวาง",
  // subDistrict: "แขวงห้วยขวาง"
};

export default function Register() {
  const [isHaveCar, setIsHaveCar] = useState("ใช่");
  const [isMitsu, setIsMitsu] = useState("ใช่");
  const [isTerm, setIsTerm] = useState(false);
  const [cookie, setCookie] = useState(false);
  const [isShowCookie, setIsShowCookie] = useState(true);
  const [isShowPDPA, setIsShowPDPA] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isAccept, setIsAccept] = useState(false);
  const [carOtherList, setCarOtherList] = useState([]);
  const [errorInput, setErrorInput] = useState([]);

  const history = useHistory();

  // GET LINE DATA
  let lineId = helper.defaultLineID;
  let LineProfile = null;
  const { isLoggedIn, liff } = useLiff();
  if (liff.getDecodedIDToken) {
    LineProfile = liff.getDecodedIDToken() ?? null;
    lineId = helper.CustomLine(LineProfile?.sub);
  }

  document.body.style.overflow =
    !isShowCookie && !isShowPDPA ? "auto" : "hidden";

  const [addressList, setAddressList] = useState(initialAddressList);
  const [address, setAddress] = useState(initialAddress);
  const [formData, setFormData] = useState({
    fullName: "",
    nickName: "",
    email: "",
    faceBookName: "",
    mobileNumber: "",
    birthdate: null,
    carDetail: null,
    carBrand: "",
    carBrandType: "",
    carBrandModel: "",
    houseNum: "",
    buildingName: "",
    alley: "",
    road: "",
    // addressDetail: "",
  });

  const [formErrors, setFormErrors] = useState({
    fullName: false,
    nickName: false,
    email: false,
    faceBookName: false,
    mobileNumber: false,
    birthdate: false,
    carDetail: false,
    carBrand: false,
    carBrandModel: false,
    houseNum: false,
    buildingName: false,
    alley: false,
    road: false,
    address: false,
    // addressDetail: false,
  });

  React.useEffect(() => {
    getSearchRegister();
    getSearchDdlCarOther();
    getSearchDdlAddress();

    // ค่าเริ่มต้นเป็นชื่อ LINE
    // setFormData({
    //   ...formData,
    //   fullName: LineProfile?.name,
    // });
  }, []);

  async function getSearchRegister() {
    try {
      const { data } = await SearchRegister({ params: { lineId } });
      if (+data["resultCode"] === 20000) {
        const body = data.body || {};
        console.log("body", body);
        if (body["memberId"]) {
          document.body.style.overflow = "auto";
          history.push("/profile");
          // window.location.replace("/edit-profile")
        }
      }
    } catch (error) {
      console.log("getSearchRegister error", error);
    }
  }

  async function getSearchDdlCarOther(search) {
    try {
      const { data } = await SearchDdlCarOther({ params: { search } });
      if (+data["resultCode"] === 20000) {
        const body = data.body || [];
        // console.log("body", body);
        if (Object.keys(body).length === 0) {
          setCarOtherList([]);
        } else {
          setCarOtherList(body);
        }
      } else {
        setCarOtherList([]);
      }
    } catch (error) {
      console.log("getSearchDdlCarOther error", error);
    }
  }

  async function getSearchDdlAddress(search) {
    try {
      const { data } = await SearchDdlAddress({ params: { search } });
      if (+data["resultCode"] === 20000) {
        const body = data.body || [];
        // console.log("body", body);
        if (Object.keys(body).length === 0) {
          setAddressList([]);
        } else {
          setAddressList(body);
        }
      } else {
        setAddressList([]);
      }
    } catch (error) {
      console.log("getSearchDdlAddress error", error);
    }
  }

  React.useEffect(() => {
    validateInput();
  }, [formData, address]);

  const handleInputChange = (event, name) => {
    console.log('handleInputChange >>', event, name)
    const target = event.target;
    if (target) {
      setFormData({ ...formData, [name]: target.value });
    } else {
      setFormData({ ...formData, [name]: event });
    }
  };

  function validateInput() {
    let carDetail = false
    let carBrandType = false
    let carBrand = false
    let carBrandModel = false
    if (isHaveCar === 'ใช่') {
      if (isMitsu === 'ใช่') {
        carDetail = !!formData.carDetail ? false : true
        if (+formData.carDetail === 24) {
          carBrand = !!formData.carBrand ? false : true
        }
      } else {
        carBrandType = !!formData.carBrandType ? false : true
        carBrand = !!formData.carBrand ? false : true
        if (+formData.carBrandType === 9999) {
          carBrandModel = !!formData.carBrandModel ? false : true
        }
      }
    }

    let updatedError = {
      fullName: !!formData.fullName ? false : true,
      nickName: !!formData.nickName ? false : true,
      email: !!formData.email ? false : true,
      mobileNumber: !!formData.mobileNumber ? false : true,
      birthdate: !!formData.birthdate ? false : true,
      houseNum: !!formData.houseNum ? false : true,
      buildingName: !!formData.buildingName ? false : true,
      alley: !!formData.alley ? false : true,
      road: !!formData.road ? false : true,
      address: address && !!address.id ? false : true,
      // addressDetail: !!formData.addressDetail ? false : true,
      isHaveCar: isHaveCar === 'ไม่ใช่' ? false : (isMitsu !== null) ? false : true,
      carDetail: carDetail,
      carBrandType: carBrandType,
      carBrandModel: carBrandModel,
      carBrand: carBrand,

    };

    let check = false;
    const entries = Object.entries(updatedError);
    console.log("entries >>", entries);
    // eslint-disable-next-line
    for (const [key, value] of entries) {
      if (value === true) {
        check = true;
        break;
      }
    }
    if (!check) {
      return true;
    }

    setFormErrors(updatedError);
    return false;
  }

  React.useEffect(() => {
    if (isSubmit) {
      console.log("useEffect isSubmit");
      sendRegister();
    }
  }, [isSubmit]);

  const sendRegister = async () => {
    setIsSubmit(true);
    const _isHaveCar = isHaveCar === "ใช่" ? "1" : "0";
    const _mobileNumber = helper.removeNot0_9(formData.mobileNumber);
    if (isSubmit) {
      let payload = {
        lineId,
        ...formData,
        mobileNumber: _mobileNumber,
        address: address.id,
        isTerm: isAccept,
        cookie,
        isHaveCar: _isHaveCar,
      };

      if (isHaveCar === 'ไม่ใช่') {
        delete payload.carBrand
        delete payload.carBrandType
        delete payload.carDetail
        delete payload.carBrandModel
      } else {
        if (isMitsu === 'ใช่') {
          if (+payload.carDetail !== 24) {
            delete payload.carBrand
          }
          delete payload.carBrandType
          delete payload.carBrandModel
        } else {
          delete payload.carDetail
          if (+payload.carBrandType !== 9999) {
            delete payload.carBrandModel
          }
        }
      }

      console.log(validateInput())
      if (isAccept && validateInput()) {
        try {
          const { data } = await CreateProfile({ data: payload });
          if (data.resultCode === 20000) {
            console.log("resData.data >>", data.body);
            history.push("/thankyou", {
              memberCode: data.body.memberCode,
            });
          } else if (data.resultCode === 40401) {
            // alert(data.body.message)
            Swal.fire({
              title: "แจ้งเตือน",
              html: data.body.message,
              icon: "warning",
            });
            setErrorInput(data.body.error);
          }
        } catch (error) {
          console.log("create pet error", error);
        }
      }
    }
  };

  return (
    <div>
      <div id="register" className="pt-[0px] z-1 relative section flex-col">
        <div className="w-full pb-[25px]">
          <img className="w-full mb-[15px]" src={banner} alt="" />
          <h1 className="text-[20px] text-center">
            กรุณากรอกข้อมูลเพื่อรับเลข <span className="font-MCC text-[15px]">M-JOURNEY</span> และสติกเกอร์
          </h1>
          <div className="px-[20px] mt-[20px] flex flex-col gap-[14px]">
            <CustomInput
              text="ชื่อ-นามสกุล"
              name="fullName"
              placeholder="กรุณากรอกชื่อ-นามสกุล"
              value={formData.fullName}
              onChange={handleInputChange}
              error={isSubmit && !formData.fullName}
              required
            />
            <CustomInput
              text="ชื่อเล่น"
              name="nickName"
              placeholder="กรุณากรอกชื่อเล่น"
              value={formData.nickName}
              onChange={handleInputChange}
              error={isSubmit && !formData.nickName}
              required
            />
            <CustomInput
              text="อีเมล"
              name="email"
              placeholder="กรุณากรอกอีเมลของคุณ"
              value={formData.email}
              onChange={handleInputChange}
              error={
                isSubmit && (!formData.email || errorInput.includes("email"))
              }
              required
            />
            <CustomInput
              text="ชื่อ Facebook"
              name="faceBookName"
              placeholder="กรุณากรอก Facebook ของคุณ"
              value={formData.faceBookName}
              onChange={handleInputChange}
              isMMC={true}
              error={
                isSubmit &&
                (!formData.faceBookName || errorInput.includes("faceBookName"))
              }
              required
            />
            <CustomInput
              text="เบอร์โทรศัพท์"
              type="number"
              name="mobileNumber"
              placeholder="กรุณากรอกเบอร์โทรศัพท์ของคุณ"
              value={formData.mobileNumber}
              onChange={handleInputChange}
              error={
                isSubmit &&
                (!formData.mobileNumber || errorInput.includes("mobileNumber"))
              }
              patternFormat={PATTERN_FORMAT.phone}
              maxLength={13}
              required
            />

            <CustomDate
              text="วัน เดือน ปี เกิด"
              type="date"
              name="birthdate"
              placeholder="กรุณากรอกวัน เดือน ปี เกิดของคุณ"
              value={formData.birthdate}
              onChange={handleInputChange}
              error={
                isSubmit &&
                (!formData.birthdate || errorInput.includes("birthdate"))
              }
              // patternFormat={PATTERN_FORMAT.birthdate}
              required
            />
            <CustomRadio
              name="isHaveCar"
              text="คุณมีรถยนต์หรือไม่"
              required
              value={isHaveCar}
              content={["ใช่", "ไม่ใช่"]}
              onChange={setIsHaveCar}
            />
            {isHaveCar === "ใช่" &&
              <>
                <CustomRadio
                  name="isMitsu"
                  text="คุณเป็นเจ้าของรถ Mitsubishi หรือไม่"
                  required
                  value={isMitsu}
                  content={["ใช่", "ไม่ใช่"]}
                  onChange={setIsMitsu}
                />
                {isMitsu === "ใช่" && (
                  <>
                    <CustomDropdown
                      type="group"
                      name="carDetail"
                      text="เลือกรุ่นรถที่คุณมี"
                      placeholder="กรุณาเลือกรุ่นรถที่คุณมี"
                      required
                      value={formData.carDetail}
                      content={carOtherList}
                      onChange={handleInputChange}
                      error={isSubmit && !formData.carDetail}
                    />
                    {
                      helper.isGroupNameOther(formData.carDetail, carOtherList) && (
                        <CustomInput
                          text="รุ่นรถของคุณ"
                          name="carBrand"
                          placeholder="กรุณาระบุรุ่นรถของคุณ"
                          value={formData.carBrand}
                          onChange={handleInputChange}
                          required
                          error={isSubmit && !formData.carBrand}
                        />
                      )
                    }

                  </>
                )}
                {isMitsu === "ไม่ใช่" && (
                  <div>
                    <CustomDropdown
                      name="carBrandType"
                      text="ยี่ห้อรถของคุณ"
                      placeholder="กรุณาเลือกยี่ห้อรถของคุณ"
                      required
                      value={formData?.carBrandType ?? ""}
                      content={CAR_OTHER_OPTION}
                      onChange={handleInputChange}
                      error={isSubmit && !formData.carBrandType}
                    />
                    {
                      +formData.carBrandType === 9999 ? (
                        <>
                          <div className="mt-5">
                            <CustomInput
                              text="ยี่ห้อรถของคุณ"
                              name="carBrand"
                              placeholder="กรุณาระบุยี่ห้อรถของคุณ"
                              value={formData.carBrand}
                              onChange={handleInputChange}
                              required
                              error={isSubmit && !formData.carBrand}
                            />
                          </div>
                          <div className="mt-5">
                            <CustomInput
                              text="รุ่นรถของคุณ"
                              name="carBrandModel"
                              placeholder="กรุณาระบุรุ่นรถของคุณ"
                              value={formData.carBrandModel}
                              onChange={handleInputChange}
                              required
                              error={isSubmit && !formData.carBrandModel}
                            />
                          </div>
                        </>
                      ) : (
                        <div className="mt-5">
                          <CustomInput
                            text="รุ่นรถของคุณ"
                            name="carBrand"
                            placeholder="กรุณาระบุรุ่นรถของคุณ"
                            value={formData.carBrand}
                            onChange={handleInputChange}
                            required
                            error={isSubmit && !formData.carBrand}
                          />
                        </div>
                      )
                    }
                  </div>
                )}
              </>
            }

            <div className="flex items-center justify-between gap-[24px]">
              <CustomInput
                text="บ้านเลขที่"
                name="houseNum"
                placeholder="กรอกบ้านเลขที่ของคุณ"
                value={formData.houseNum}
                onChange={handleInputChange}
                required
                error={isSubmit && !formData.houseNum}
              />
              <CustomInput
                text="หมู่บ้าน/อาคาร"
                name="buildingName"
                placeholder="กรอกที่อยู่ของคุณ"
                value={formData.buildingName}
                onChange={handleInputChange}
                required
                error={isSubmit && formErrors.buildingName}
              />
            </div>
            <div className="flex items-center justify-between gap-[24px]">
              <CustomInput
                name="alley"
                text="ซอย"
                placeholder="กรอกที่อยู่ของคุณ"
                value={formData.alley}
                onChange={handleInputChange}
                required
                error={isSubmit && !formData.alley}
              />
              <CustomInput
                name="road"
                text="ถนน"
                placeholder="กรอกที่อยู่ของคุณ"
                value={formData.road}
                onChange={handleInputChange}
                required
                error={isSubmit && !formData.road}
              />
            </div>
            <CustomAutocomplete
              text="รหัสไปรษณีย์"
              name="zipcode"
              placeholder="กรอกที่อยู่ของคุณ"
              value={address?.zipcode ?? ""}
              InputCallBack={getSearchDdlAddress}
              onChange={(value, name, selectOption) => {
                setAddress(selectOption);
              }}
              required
              options={addressList}
              error={isSubmit && Object.keys(address).length === 0}
            />

            <CustomAutocomplete
              name="subDistrict"
              text="แขวง/ตำบล"
              placeholder="เลือก แขวง/ตำบล ของคุณ"
              value={address?.subDistrict ?? ""}
              InputCallBack={getSearchDdlAddress}
              onChange={(value, name, selectOption) => {
                setAddress(selectOption);
              }}
              required
              options={addressList}
              error={isSubmit && Object.keys(address).length === 0}
            />

            <CustomAutocomplete
              name="district"
              text="เขต/อำเภอ"
              placeholder="เลือก เขต/อำเภอ ของคุณ"
              value={address?.amphoe ?? ""}
              InputCallBack={getSearchDdlAddress}
              onChange={(value, name, selectOption) => {
                setAddress(selectOption);
              }}
              required
              options={addressList}
              error={isSubmit && Object.keys(address).length === 0}
            />

            <CustomAutocomplete
              name="province"
              text="จังหวัด"
              placeholder="เลือกจังหวัดของคุณ"
              value={address?.province ?? ""}
              InputCallBack={getSearchDdlAddress}
              onChange={(value, name, selectOption) => {
                setAddress(selectOption);
              }}
              required
              options={addressList}
              error={isSubmit && Object.keys(address).length === 0}
            />
            <div className="flex items-start gap-[8px]">
              <Checkbox
                className="!p-0 !pt-[4px]"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                checked={isAccept}
                onChange={(e) => {
                  setIsAccept(e.target.checked);
                }}
              />
              <p className="text-[20px] text-left">
                ข้าพเจ้าได้อ่าน{" "}
                <span
                  className="underline cursor-pointer decoration-1"
                  onClick={() => setIsShowPDPA(true)}
                >
                  นโยบายความเป็นส่วนตัว
                </span>{" "}
                และยินยอมรับเงื่อนไขของการ เป็นสมาชิก
              </p>
            </div>
          </div>

          <div className="section mt-[35px]">
            <CustomGradientButton
              text="ลงทะเบียน"
              disable={!isAccept}
              function={sendRegister}
            />
          </div>
        </div>
        <img className="w-full" src={tyFooter} alt="tyFooter" />
      </div>
      {isShowCookie && (
        <Cookie
          function={(isOk = false) => {
            setIsShowCookie(false);
            // setIsTerm(isOk)
            setCookie(isOk);
          }}
          openPdpa={() => setIsShowPDPA(true)}
        />
      )}
      {isShowPDPA && <PDPA function={() => setIsShowPDPA(false)} />}
    </div>
  );
}
