import React from "react";

export default function CustomRadio(props) {
  return (
    <div>
      <p className="text-[20px] text-left">
        {props.text}
        {props.required && <span className="!text-red"> *</span>}
      </p>

      <div className="flex items-center gap-[20px]">
        {props.content &&
          props.content.map((item, i) => (
            <div className="flex items-center gap-[8px]" key={i}>
              <input
                type="radio"
                id={item}
                name={props.name}
                checked={props.value === item}
                disabled={!!props.disabled}
                onChange={() => {
                  if (props.onChange) props.onChange(item);
                }}
              />
              <p className="text-[20px]">{item}</p>
            </div>
          ))}
      </div>
    </div>
  );
}
