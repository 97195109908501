import React from "react";
import runningMember from "../assets/images/thankyou/running-member.png";
import CustomButton from "../components/global/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";

import { useHistory } from "react-router-dom";
// import journey from "../assets/images/thankyou/journey.svg"
import tyFooter from "../assets/images/thankyou/bg-footer.png"

export default function Thankyou() {
  const history = useHistory();
  // const { state } = useLocation();
  const { state } = useLocation();
  console.log('state >>', state)
  
  console.log('state memberCode >>', state.memberCode)
  return (
    <div className="pt-[0px]" >
      <div id="thankyou" className="relative section_thankyou">
        <div className="w-full pb-[50px] px-[0px] pt-[0px]">
          {/* <img className="w-full" src={journey} alt="" /> */}
          <div className="pt-[35px] pb-[28px]">
            <h1 className="text-[34px] font-bold leading-[80%] text-center">ยินดีด้วย</h1>
            <p className="mt-[8px] text-[24px] text-center leading-[28px]">
              คุณเป็นสมาชิกของ
            </p>
            <p className="mt-[-2px] text-[24px] text-center leading-[28px]">
              <span className="font-MCC text-[28px]">M-JOURNEY</span> แล้ว
            </p>
          </div>
          <div
            style={{
              backgroundImage: `url(${runningMember})`,
            }}
            className="bg-cover bg-center object-fit rounded-[0px] min-h-[150px] section_member relative"
          >
            {/* <h1 className="text-white text-[24px] font-bold">
              หมายเลขสมาชิกของคุณคือ
            </h1> */}
            <h1 className="font-bold leading-[50px] running-text absolute left-[61%] top-[19.5%]">
              { state.memberCode }
            </h1>
          </div>

          <p className="text-gray text-[16px] mt-[8px] text-center">
            *สติกเกอร์ของคุณจะถูกจัดส่งตามที่อยู่ที่แจ้งไว้
          </p>

          <div className="section mt-[50px]">
            <CustomButton
              text="ไปหน้าโปรไฟล์"
              function={() => {
                history.push("/profile");
              }}
            />
          </div>
        <img className="w-full" src={tyFooter} alt=""/>
        </div>
      </div>
    </div>
  );
}
