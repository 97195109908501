import logdna from '@logdna/browser';
import dayjs from "dayjs";
const LOGDNA_INGESTION_KEY = 'a0ce31b529dbd2b0b4afab4c7e827f2f';


logdna.init(LOGDNA_INGESTION_KEY, {
    console: false,
    app: 'MMTH Line React',
})

const DnaLevel = {
    info:"INFO",
    error:"ERROR",
    warn:"WARN",
    trace:"TRACE",
    debug:"DEBUG",
    fatal:"FATAL"
}

const getLineId = () => {
  const lineInfo =  window.localStorage.getItem('sub')
  return lineInfo
  // return 'xxxxxx'
}

const getPlatform = () => {
  let _platform = 'Desktop';
  let userAgent = window.navigator.userAgent.toString().toLowerCase();

  if (userAgent.indexOf("iphone") !== -1) _platform = "iOS";
  if (userAgent.indexOf("ipad") !== -1) _platform = "iOS";
  if (userAgent.indexOf("android") !== -1) _platform = "Android";

  return _platform
}

const randomString = (length) => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}


export const Logger = {
  info: (realApi = '', meta) => {
      const lineId = getLineId()
      let platform = getPlatform()
      const logInfo = {
          timestamp: dayjs().toISOString(),
          line: `${platform} ${lineId} API ${realApi}`,
          level: DnaLevel.info,
          app: `MMTH Line React ${platform}`,
          env: 'staging',
          meta: meta,
      }
      logdna.log(logInfo.line, logInfo.meta, logInfo.level);
  },
  error: (realApi = '', meta) => {
      const lineId = getLineId()
      let platform = getPlatform()

      const logError = {
          timestamp: dayjs().toISOString(),
          line: `${platform} ${lineId} API ${realApi}`,
          level: DnaLevel.error,
          app: `MMTH Line React ${platform}`,
          env: 'staging',
          meta: meta,
      }

      logdna.log(logError.line, logError.meta, logError.level);
  },
  normal: (text = '') => {
    let platform = getPlatform()
    const str = platform + '' + text
    logdna.log(str, ' ', DnaLevel.info);
  },
  getTranID: (txt = 'mmth-fe') => {
    return txt + dayjs().format("YYYYMMDD") + new Date().toLocaleTimeString().replace(':', '').replace(':', '')+randomString(5);
  },
 
}