import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

export default function PDPA(props) {
  return (
    <div
      id="pdpa"
      className="fixed top-0 bottom-0 left-0 right-0 min-h-screen z-[101] bg-white"
    >
      <div className="flex justify-between px-[24px] py-[28px]">
        <h1 className="text-[24px] font-bold text-left">
          ประกาศนโยบายความเป็นส่วนตัว
        </h1>
        <FontAwesomeIcon
          icon={faClose}
          className="h-[24px]"
          onClick={() => {
            if (props.function) props.function();
          }}
        />
      </div>
      <div className="p-[20px] h-[calc(100vh-92px)] overflow-y-scroll bg-lightGray scrollbar-red">
        <p className="text-[24px] font-bold text-left">
          บริษัท มิตซูบิชิ มอเตอร์ส (ประเทศไทย) จำกัด
          นโยบายคุ้มครองข้อมูลส่วนบุคคล
        </p>
        <p className="mt-[16px] text-[20px] text-left">
          <span className="font-bold">
            1. คำแถลงว่าด้วยการคุ้มครองข้อมูลส่วนบุคคล <br />
          </span>
          1.1 นโยบายคุ้มครองข้อมูลส่วนบุคคลนี้ (“นโยบายคุ้มครองข้อมูลส่วนบุคคล”)
          อธิบายวิธีการที่บริษัท มิตซูบิชิ มอเตอร์ส (ประเทศไทย) จำกัด
          (ซึ่งต่อไปนี้จะเรียกว่า “MMTh” “เรา” หรือ “ของเรา”) เก็บรวบรวม ใช้
          เก็บรักษา เปิด เผย และ/หรือ โอนไปยังต่างประเทศ
          ซึ่งข้อมูลส่วนบุคคลของลูกค้า ในปัจจุบันและอนาคต (“คุณ” หรือ “ของคุณ”)
          (ตามที่นิยามไว้ใน นโยบายนี้)
          โดยมีวัตถุประสงค์เพื่อคุ้มครองความเป็นส่วนตัวของ ข้อมูลส่วนบุคคลของคุณ
          <br />
          <br />
          1.2 นโยบายคุ้มครองข้อมูลส่วนบุคคลนี้ใช้กับช่องทางการสื่อสาร
          ทั้งออนไลน์หรือออฟไลน์ที่เราเก็บรวบรวมข้อมูลส่วนบุคคลของคุณ
          ไม่ว่าทางต่อหน้า ผ่านศูนย์บริการ โรงงาน สถานที่ประกอบ กิจการของเรา
          งานกิจกรรม หรือ ทางโทรศัพท์ผ่านศูนย์บริการ ข้อมูลลูกค้า (call center)
          หรือทางออนไลน์ ผ่านทางอีเมล หรือ แพลตฟอร์มโซเชียลมีเดีย (เช่น เว็บเพจ
          Facebook หรือ Line) และช่องทางอื่นๆ
          ที่เกี่ยวกับการจัดหาผลิตภัณฑ์และบริการให้แก่ คุณ
          รวมถึงการใช้แอปพลิเคชัน Smartphone Link Gateway for M Connect)
          และ/หรือแอปพลิเคชันอื่นๆ (“แอปพลิเคชัน”) เว็บไซต์ของเรา
          https://www.mitsubishi-motors.co.th/th และ/หรือเว็บไซต์อื่นๆ
          (“เว็บไซต์”) และปฏิสัมพันธ์อื่นๆ ระหว่าง คุณกับเรา
          (ซึ่งต่อไปนี้จะรวมเรียกกันว่า “ช่องทางการสื่อสาร”)
          <br />
          <br />
          1.3 เพื่อให้บรรลุวัตถุประสงค์ของนโยบายคุ้มครองข้อมูลส่วน บุคคลนี้
          “ข้อมูลส่วนบุคคล” หมายถึงข้อมูลส่วนบุคคลหรือข้อมูล
          ที่สามารถระบุตัวตนได้ตามที่นิยามไว้ในกฎหมายที่ใช้บังคับ ซึ่ง
          รวมถึงข้อมูลส่วนบุคคลที่มีความละเอียดอ่อน (ตามที่นิยามไว้ใน นโยบายนี้)
          <br />
          <br />
          1.4 MMTh มีความมุ่งมั่นในการดำเนินการเพื่อให้มั่นใจว่าความ
          เป็นส่วนตัวของคุณจะได้รับความคุ้มครอง และการประมวลผล
          ข้อมูลส่วนบุคคลของคุณจะเป็นไปตามกฎหมายและข้อบังคับที่ เกี่ยวข้อง
          (ซึ่งอาจมีการแก้ไขเพิ่มเติมและมีการออกกฎหมายใหม่ใช้
          บังคับแทนเป็นครั้งคราว) เพื่อคุ้มครองข้อมูลส่วนบุคคลในประเทศ
          ที่เราเข้าไปและมุ่งหมายจะเข้าไปดำเนินธุรกิจ (“กฎหมายที่ใช้ บังคับ”)
          MMTh จะเก็บรวบรวม ใช้ เก็บรักษา เปิดเผย และโอนไป
          ยังต่างประเทศซึ่งข้อมูลส่วนบุคคลของคุณตามนโยบายคุ้มครอง
          ข้อมูลส่วนบุคคลนี้เท่านั้น
          <br />
          <br />
          1.5 โปรดอ่านเนื้อหาต่อไปนี้อย่างระมัดระวังเพื่อทำความเข้าใจ
          มุมมองและการปฏิบัติของ MMTh เกี่ยวกับข้อมูล ส่วนบุคคลของ
          คุณและวิธีการที่เราจะประมวลผลข้อมูลดังกล่าว ข้อมูลบางประการ
          เกี่ยวกับคุณมีความจำเป็นสำหรับการจัดหาผลิตภัณฑ์และบริการให้แก่คุณ
          หากคุณไม่ตกลงที่จะให้ข้อมูลดังกล่าว เราจะไม่สามารถ
          จัดหาผลิตภัณฑ์และบริการให้แก่คุณ และคุณจะไม่สามารถใช้หรือ
          เข้าถึงฟังก์ชั่นและการใช้งานของบริการและผลิตภัณฑ์ของเรา ทั้งหมดได้
          <br />
          <br />
          1.6 โดยทั่วไปแล้วกิจกรรมของเรา (ซึ่งรวมถึงการให้บริการ และการ
          จำหน่ายผลิตภัณฑ์) ไม่มุ่งเน้นที่ผู้เยาว์ คนเสมือนไร้ความสามารถ
          หรือคนไร้ความสามารถ ดังนั้น หากคุณมีอายุต่ำกว่า 20 ปี หรือ
          ยังไม่บรรลุนิติภาวะตามที่กำหนดในประเทศที่คุณอาศัยอยู่ เป็นคน
          เสมือนไร้ความสามารถ เป็นคนไร้ความสามารถ (แล้วแต่กรณี) เว้น
          แต่จะมีสิทธิตามกฎหมาย และคุณต้องการใช้บริการของเรา ใช้แอป
          พลิเคชันของเรา หรือบริการอื่นๆ จากเรา คุณจะต้องได้รับความ
          ยินยอมจากผู้ปกครองหรือผู้แทนโดยชอบธรรมก่อนติดต่อเรา
          หรือก่อนให้ข้อมูลส่วนบุคคลแก่เรา เราไม่เก็บรวบรวมข้อมูลส่วน
          บุคคลหากเราทราบอยู่แล้วว่าข้อมูลดังกล่าวเป็นข้อมูลของผู้เยาว์ที่อายุน้อยกว่า
          20 ปีโดยไม่ได้รับความยินยอมจากผู้ปกครองตาม ที่กฎหมายกำหนด
          หรือเป็นของคนเสมือนไร้ความสามารถหรือ
          คนไร้ความสามารถโดยไม่ได้รับความยินยอมจากผู้พิทักษ์หรือผู้
          อนุบาลตามกฎหมาย
          <br />
          <br />
          1.7 นโยบายคุ้มครองข้อมูลส่วนบุคคลนี้ได้รับการปรับปรุงครั้ง ล่าสุดเมื่อ
          1 พฤศจิกายน พ.ศ. 2564 MMTh สงวนสิทธิ์ โดยมี ดุลยพินิจแต่เพียงผู้เดียว
          ในการปรับเปลี่ยน แก้ไข ลบ และปรับปรุง
          นโยบายคุ้มครองข้อมูลส่วนบุคคลนี้เป็นครั้งคราว MMTh จะใช้
          ความพยายามตามสมควรเพื่อแจ้งให้คุณทราบและในลักษณะที่
          เหมาะสมถึงการปรับเปลี่ยนข้อกำหนดใดของนโยบายคุ้มครองข้อมูลส่วนบุคคลนี้
          (เช่น โดยส่งนโยบายที่แก้ไขให้คุณทางอีเมล หรือโดย
          ประกาศการเปลี่ยนแปลงดังกล่าวในช่องทางการสื่อสารของเรา)
          หากการเปลี่ยนแปลงใดๆ ของนโยบายคุ้มครองข้อมูลส่วนบุคคล
          นี้ต้องได้รับความยินยอมจากคุณตามกฎหมายที่ใช้บังคับ เราจะ
          ขอความยินยอมจากคุณเพื่อให้สอดคล้องกับการเปลี่ยนแปลงดังกล่าว
        </p>
      </div>
    </div>
  );
}
