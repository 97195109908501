import React from "react";

export default function CustomGradientButton(props) {
  const disable = props.disable ? 'disabled' : ''
  return (
    <div
      className={"cursor-pointer w-fit red-gradient-box py-[9px] px-[48px] rounded-full !text-white " + disable}
      onClick={() => {
        if (props.function) props.function();
      }}
    >
      <div className="text-red text-[20px] font-bold text-center">
        {props.text}
      </div>
    </div>
  );
}
