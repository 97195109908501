import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from '@mui/material/InputLabel';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export default function CustomDropdown(props) {
  const ITEM_HEIGHT = 60;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 50,
      },
    },
  };

  if (props.type === 'group') {
    return (
      <div>
        <p className="text-[20px] text-left">
          {props.text}
          {props.required && <span className="!text-red"> *</span>}
        </p>
        {/* <InputLabel htmlFor="grouped-native-select">{props.placeholder}</InputLabel> */}
        <FormControl fullWidth className="!m-0">
          <Select
            native
            IconComponent={KeyboardArrowDownIcon}
            className="text-left border-[1px] border-black py-[7px] px-[20px] text-[16px] rounded-full w-full select-custom"
            disableUnderline
            displayEmpty
            value={props.value}
            // defaultValue={""}
            onChange={(e) => {
              props.onChange(e, props?.name);
            }}
            error={props.error}
          >
            <option aria-label="None" value={""}>{props.placeholder}</option>
            {
              props.content &&
              props.content.map((item, i) => (
                <optgroup label={item.groupName} key={item.id}>
                  {
                    item.carList &&
                    item.carList.map((item2, i2) => (
                      <option key={item2.id} value={item2.id}>{item2.name}</option>
                    ))
                  }
                </optgroup>
              ))
            }
          </Select>
        </FormControl>
      </div>
    );
  }
  return (
    <div>
      <p className="text-[20px] text-left">
        {props.text}
        {props.required && <span className="!text-red"> *</span>}
      </p>
      <FormControl fullWidth className="!m-0">
        <Select
          native
          IconComponent={KeyboardArrowDownIcon}
          className="text-left border-[1px] border-black py-[7px] px-[20px] text-[16px] rounded-full w-full select-custom"
          disableUnderline
          displayEmpty
          value={props.value}
          // defaultValue={""}
          onChange={(e) => {
            props.onChange(e, props?.name);
          }}
          error={props.error}
        >
          <option aria-label="None" value={""}>{props.placeholder}</option>
          {
            props.content &&

            <optgroup>
              {
                props.content.map((item, i) => (
                  <option key={item.id} value={item.id}>{item.name}</option>
                ))
              }
            </optgroup>

          }
        </Select>
        {/* <Select
          IconComponent={KeyboardArrowDownIcon}
          className="text-left border-[1px] border-black py-[7px] px-[20px] text-[16px] rounded-full w-full"
          disableUnderline
          displayEmpty
          // defaultValue={""}
          onChange={(e) => {
            props.onChange(e, props?.name);
          }}
          value={props.value}
          // error={props.error}
          error={true}
          MenuProps={MenuProps}
        >
          <MenuItem value="" disabled>
            {props.placeholder}
          </MenuItem>
          {props.content &&
            props.content.map((item, i) => (
              <MenuItem
                key={i}
                className="!text-left py-[7px] px-[20px] !text-[16px] w-full"
                value={item.id}
              >
                {item.name}
              </MenuItem>
            ))}
        </Select> */}
      </FormControl>
    </div>
  );
}
