import React from "react";
import logo from "../assets/images/global/Logo.png";
import { useHistory } from "react-router-dom";

export default function Navbar() {

  const history = useHistory();
  
  return (
    <div id="navbar" className="navbar-section w-full">
      <div className="navbar-container">
        <div className="section py-[14px]" onClick={()=> history.push("/profile")}>
          <img className="w-[42.42px]" src={logo} alt="" />
        </div>
      </div>
    </div>
  );
}
