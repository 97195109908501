import React, { useState } from "react";
import banner from "../assets/images/share-event/banner-share-events.png";
import EventComponent from "../components/event/EventComponent";
import CustomPagination from "../components/global/CustomPagination";

import { SearchId } from "../services/SearchId";
import { SearchProfile, UpdateProfile } from "../services/Register"
import { SearchShareEvent } from "../services/ShareEvent";
import { useLiff } from 'react-liff';
import helper from "../services/helper";

export default function Event() {
  const [memberId, setMemberId] = useState(null);

  // GET LINE DATA
  let lineId = helper.defaultLineID
  let LineProfile = null
  const { isLoggedIn, liff } = useLiff();
  if (liff.getDecodedIDToken) {
    LineProfile = liff.getDecodedIDToken() ?? null
    lineId = helper.CustomLine(LineProfile?.sub)
  }

  const [shareEvent, setShareEvent] = useState([]);
  const [shareEventCount, setShareEventCount] = useState(0);
  const [limit, setLimit] = useState(4);
  const [offset, setOffset] = useState(0);

  React.useEffect(() => {
    if (memberId) {
      getProfile()
    }
  }, [memberId])

  React.useEffect(() => {
    getSearchShareEvent({ limit, offset })
  }, [offset])


  async function getSearchId() {
    return await SearchId({
      params: { lineId: lineId || null },
    })
      .then(async (response) => {
        if (!response.data.body.memberId) {
          window.location.replace("/register");
        } else {
          setMemberId(response.data.body.memberId);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getProfile() {
    return await SearchProfile({
      params: { memberId: memberId },
    })
      .then(async (response) => {
        const resData = response.data
        if (resData.resultCode == "20000") {
          console.log('resData.body >>', resData.body)
          const body = resData.body
        } else {
          // navigate(-1)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getSearchShareEvent(search) {
    console.log('search >>', search)
    try {
      const { data } = await SearchShareEvent({ params: { ...search } });
      if (+data["resultCode"] === 20000) {
        const body = data.body || [];
        console.log("body", body);
        if (Object.keys(body).length === 0) {
          setShareEvent([]);
          setShareEventCount(0)
        } else {
          setShareEvent(body);
          setShareEventCount(data.count)
        }
      } else {
        setShareEvent([]);
        setShareEventCount(0)
      }
    } catch (error) {
      console.log("getSearchShareEvent error", error);
    }
  }
  return (
    <div>
      <div id="share-event" className="pt-[0px] z-1 relative section">
        <div className="w-full pb-[50px]">
          <img className="w-full" src={banner} alt="" />
          {shareEvent.length > 0 ? (
            <div>
              <div className="px-[20px] mt-[24px] flex flex-col gap-[16px]">
                {shareEvent?.map((item, i) => (
                  <EventComponent key={i} data={item} />
                ))}
              </div>
              <CustomPagination
                count={shareEventCount}
                limit={limit}
                offset={offset}
                onChange={({ count, limit, offset }) => {
                  setOffset(offset)
                }} />
            </div>
          ) : (
            <p className="mt-[30px] text-[20px]" style={{color : "#B7B7B7"}}>เตรียมพบกับกิจกรรมที่ทางเราจะจัดขึ้นในเร็วๆนี้</p>
          )}
        </div>
      </div>
    </div>
  );
}
